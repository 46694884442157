<template>
  <v-card
    :loading="isLoading"
    class="mx-auto theme-card"
    :class="{ 'has-error': hasError !== null }"
    flat
  >
    <v-card-text>
      <v-row no-gutters>
        <p class="display-1 text--primary">Theme</p>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="!!selectedThemeInfo">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              icon
              v-on="on"
              @click="copyShareLinkToClipboard"
              :disabled="!selectedThemeInfo.editable"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <span>Copy share link to clipboard</span>
        </v-tooltip>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <p>Themes:</p>
          <v-row align="center" no-gutters>
            <v-select
              outlined
              dense
              class="my-3"
              auto-select-first
              v-model="selectedTheme"
              :value="selectedTheme"
              :items="themes"
              item-text="name"
              label="Select Theme"
            >
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      icon
                      v-on="on"
                      @click="duplicateThemeClick"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>Duplicate</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="selectedTheme">
        <v-col cols="12">
          <v-form>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Title"
                  v-model="selectedTheme.title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  label="Logo"
                  v-model="selectedTheme.logo"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="pa-4">
                <h1>Colours</h1>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-col cols="5">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="key"
                  value="Dark Accent"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  dense
                  label="value"
                  v-model="selectedTheme.theme.primary.darken1"
                >
                  <template v-slot:append-outer>
                    <v-sheet
                      height="30px"
                      width="30px"
                      :color="selectedTheme.theme.primary.darken1"
                    ></v-sheet>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-col cols="5">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="key"
                  value="Primary Accent"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  dense
                  label="value"
                  v-model="selectedTheme.theme.primary.base"
                >
                  <template v-slot:append-outer>
                    <v-sheet
                      height="30px"
                      width="30px"
                      :color="selectedTheme.theme.primary.base"
                    ></v-sheet>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-col cols="5">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="key"
                  value="secondary Accent"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  dense
                  label="value"
                  v-model="selectedTheme.theme.secondary"
                >
                  <template v-slot:append-outer>
                    <v-sheet
                      height="30px"
                      width="30px"
                      :color="selectedTheme.theme.secondary"
                    ></v-sheet>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" dense>
              <v-col cols="5">
                <v-text-field
                  outlined
                  disabled
                  dense
                  label="key"
                  value="Tertiary Accent"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  dense
                  label="value"
                  v-model="selectedTheme.theme.tertiary"
                >
                  <template v-slot:append-outer>
                    <v-sheet
                      :color="selectedTheme.theme.tertiary"
                      width="30px"
                      height="30px"
                    ></v-sheet>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="cancelClick">Cancel</v-btn>
      <v-btn
        color="primary"
        @click="applyClick"
        :disabled="selectedTheme === null"
      >
        {{ $t('apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import localstorageUtils from '@/core/utils/localstorage';

export default {
  name: 'ThemesCard',
  props: ['themes', 'hasError', 'selectedThemeModel', 'isLoading', 'prevRoute'],
  data() {
    return {
      selectedTheme: null,
      themeDataKeys: {},
      themeDataValue: {},
      dirtyForm: false,
      themeColors: {},
    };
  },
  computed: {
    themeNames() {
      return this.themes.map((theme) => theme.name);
    },

    selectedThemeInfo() {
      if (this.selectedTheme) {
        return this.findSelectedTheme(this.selectedTheme['app-id']);
      } else {
        return null;
      }
    },
  },
  watch: {
    selectedThemeModel: {
      immediate: true,
      deep: true,
      handler(selectedThemeModel) {
        if (selectedThemeModel)
          this.selectedTheme = {
            ...selectedThemeModel,
            primary: { ...selectedThemeModel.primary },
          };
      },
    },
    selectedTheme(newVal) {
      this.dirtyForm = false;
    },
  },
  methods: {
    findSelectedTheme(appId) {
      const selected = this.themes.filter(
        (theme) => theme.value['app-id'] === appId
      );
      if (selected.length > 0) return selected[0];
      else return null;
    },
    duplicateThemeClick() {
      if (!!this.selectedTheme) {
        const newTheme = this.duplicateTheme(this.selectedThemeInfo);
        this.selectedTheme = newTheme.value;
      }
    },
    duplicateTheme(theme, themeValues) {
      if (!!theme) {
        const newTheme = {
          ...theme,
          name: `${theme.name}-${Date.now()}`,
          editable: true,
          value: {
            ...this.selectedTheme,
            'app-id': `glx-${Date.now()}`,
            theme: { ...theme.value.theme, ...themeValues },
          },
        };
        this.themes.push(newTheme);
        return newTheme;
      }
    },
    cancelClick() {
      if (!!this.prevRoute) {
        this.$router.push(this.prevRoute);
      } else {
        this.$router.push({ name: 'home' });
      }
    },
    resetClick() {
      const theme = this.selectedTheme;
      this.selectedTheme = { ...theme };
      this.themeDataKeys = Object.keys(this.selectedTheme.theme);
      this.themeDataValue = Object.values(this.selectedTheme.theme);
      this.applyTheme(this.selectedTheme);
    },
    applyClick() {
      let theTheme = this.selectedThemeInfo;
      const themeToApply = { ...this.selectedTheme.theme };
      if (!theTheme.editable && this.dirtyForm) {
        theTheme = this.duplicateTheme(theTheme, themeToApply);
        this.selectedTheme = theTheme.value;
      } else if (theTheme.editable) {
        theTheme.value.theme = { ...themeToApply };
        this.selectedTheme = theTheme.value;
      } else {
        theTheme = {
          ...theTheme,
          value: {
            ...theTheme.value,
            theme: { ...themeToApply },
          },
        };
        this.selectedTheme = theTheme.value;
      }

      this.applyTheme({ ...theTheme.value });
      this.saveThemesToLocalstorage();
    },
    applyTheme(theme) {
      this.$store.dispatch('setConfigs', theme);
    },
    saveThemesToLocalstorage() {
      const newThemes = this.themes.filter((theme) => theme.editable);
      localstorageUtils.setThemes(newThemes);
    },
    copyShareLinkToClipboard() {
      const customTheme = JSON.stringify([this.selectedThemeInfo]);
      const qParams = encodeURIComponent(`${customTheme}`);
      const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      };
      copyToClipboard(
        `${window.location.origin}${window.location.pathname}?ct=${qParams}`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.theme-card {
  border-top: 4px solid var(--v-secondary-base);
  box-sizing: border-box;
  border-radius: 4px;
  &.has-error {
    border-top: 4px solid var(--v-error-base);
  }
}
</style>
