<template>
  <v-container fluid>
    <v-banner
      v-if="hasError !== null"
      single-line
      :sticky="true"
      style="z-index:3;"
      class="error-banner"
    >
      <v-avatar slot="icon" color="error" size="40">
        <v-icon icon="mdi-lock" color="white">mdi-alert</v-icon>
      </v-avatar>
      <div class="title">{{ hasError }}</div>

      <template v-slot:actions>
        <v-btn text color="deep-purple accent-4" @click="refresh"
          >Refresh</v-btn
        >
      </template>
    </v-banner>
    <v-row align="start" justify="center">
      <v-col cols="12" sm="5">
        <ThemesColorPalette />
      </v-col>
      <v-col cols="12" sm="6">
        <ThemesCard
          :themes="themes"
          :hasError="hasError"
          :prev-route="prevRoute"
          :selected-theme-model="selectedTheme"
          :is-loading="isLoading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localstorageUtils from '@/core/utils/localstorage';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import ThemesCard from '@/modules/themeify/ThemesCard';
import ThemesColorPalette from '@/modules/themeify/ThemesColorPalette';
export default {
  name: 'themeify',
  components: { ThemesColorPalette, ThemesCard },
  props: ['queryCustomTheme'],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      const enableTheme = await vm.$ldclient.variation(
        'themes-page',
        false,
        true
      );
      if (enableTheme) {
        vm.prevRoute = from;
      } else {
        next(from);
      }
    });
  },
  data() {
    return {
      themes: [],
      selectedTheme: null,
      themeDataKeys: {},
      themeDataValue: {},
      dirtyForm: false,
      hasError: null,
      prevRoute: null,
      isLoading: false,
    };
  },
  computed: {
    appliedThemeAppId() {
      return this.$store.state.configs['app-id'];
    },
  },
  created() {
    const customThemes = this.tryLoadCustomThemes();
    this.getThemeData(customThemes);
  },
  beforeDestroy() {
    this.saveThemesToLocalstorage();
  },
  methods: {
    saveThemesToLocalstorage() {
      const newThemes = this.themes.filter((theme) => theme.editable);
      localstorageUtils.setThemes(newThemes);
    },
    tryLoadCustomThemes() {
      const customThemes = localstorageUtils.getThemes() || [];
      if (this.queryCustomTheme) {
        try {
          const qTheme = JSON.parse(this.queryCustomTheme);
          if (!!qTheme && qTheme.length > 0) {
            const newTheme = qTheme[0];
            const oldThemeIndex = customThemes.findIndex(
              (theme) => theme.name === newTheme.name
            );
            if (oldThemeIndex > -1) {
              customThemes[oldThemeIndex] = newTheme;
            } else {
              customThemes.push(newTheme);
            }
          }
        } catch (err) {
          Sentry.captureException(err);
        }
      }
      return customThemes;
    },

    async getThemeData(customThemes) {
      this.hasError = null;
      this.isLoading = true;
      try {
        const { data } = await axios.get('/themes');
        this.themes = [...data.themes, ...customThemes];
        this.isLoading = false;
        this.initSelectedTheme(this.themes);
      } catch (error) {
        this.hasError = error;
        this.isLoading = false;
      } finally {
        this.saveThemesToLocalstorage();
      }
    },
    initSelectedTheme(themes) {
      let theme = themes.find(
        (theme) => theme.value['app-id'] === this.appliedThemeAppId
      );
      if (!!theme) {
        this.selectedTheme = { ...theme.value };
      }
    },
    refresh() {
      this.getThemeData();
    },
    applyTheme(theme) {
      this.$store.dispatch('setConfigs', theme);
    },
  },
};
</script>
