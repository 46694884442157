<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Title
            </th>
            <th class="text-left">
              CSS Variable
            </th>
            <th class="text-left">
              Preview
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(color, i) in colors" :key="`grey-${i}`">
            <td>{{ color.title }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    @click="onCopy(`var(${color.cssVariable})`)"
                    v-bind="attrs"
                    v-on="on"
                    >{{ color.cssVariable }}</span
                  >
                </template>
                <span>Copy</span>
              </v-tooltip>
            </td>
            <td>
              <div
                :style="{
                  background: `var(${color.cssVariable})`,
                  height: '30px',
                }"
              ></div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'ThemesColorsTable',
  props: ['colors'],
  data() {
    return {
      snackbarText: '',
      snackbar: false,
    };
  },
  methods: {
    onCopy(text) {
      this.copyToClipboard(text);
      this.snackbarText = `Copied ${text}`;
      this.snackbar = true;
    },
    copyToClipboard(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
  },
};
</script>

<style scoped></style>
