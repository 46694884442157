<template>
  <v-card flat class="theme-card">
    <v-card-title
      ><div class="display-1 text--primary">Color Palette</div></v-card-title
    >
    <v-card-text>
      <v-container
        v-for="(colorGroup, i) in colorGroups"
        :key="`color-group-${i}`"
      >
        <v-row>
          <v-col>
            <h5 class="text-h5">{{ colorGroup.heading }}</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ThemesColorsTable :colors="colorGroup.colors" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import ThemesColorsTable from '@/modules/themeify/ThemesColorsTable';
export default {
  name: 'ThemesColorPalette',
  components: { ThemesColorsTable },
  data() {
    return {
      coolGreys: [
        {
          title: '01 Grey',
          cssVariable: '--v-grey1-base',
        },
        {
          title: '02 Grey',
          cssVariable: '--v-grey2-base',
        },
        {
          title: '03 Grey',
          cssVariable: '--v-grey3-base',
        },
        {
          title: '04 Grey',
          cssVariable: '--v-grey4-base',
        },
        {
          title: '05 Grey',
          cssVariable: '--v-grey5-base',
        },
        {
          title: '06 Grey',
          cssVariable: '--v-grey6-base',
        },
        {
          title: '07 Grey',
          cssVariable: '--v-grey7-base',
        },
        {
          title: '08 Grey',
          cssVariable: '--v-grey8-base',
        },
        {
          title: '09 Grey',
          cssVariable: '--v-grey9-base',
        },
      ],
      whiteLabelAccents: [
        {
          title: 'Dark Accent',
          cssVariable: '--v-primary-darken1',
        },
        {
          title: 'Primary Accent',
          cssVariable: '--v-primary-base',
        },
        {
          title: 'Secondary Accent',
          cssVariable: '--v-secondary-base',
        },
        {
          title: 'Tertiary Accent',
          cssVariable: '--v-tertiary-base',
        },
      ],
      status: [
        {
          title: 'Success Primary',
          cssVariable: '--v-success-base',
        },
        {
          title: 'Success Secondary',
          cssVariable: '--v-success-secondary-base',
        },
        {
          title: 'Success tertiary',
          cssVariable: '--v-success-tertiary-base',
        },
        {
          title: 'Info Primary',
          cssVariable: '--v-info-base',
        },
        {
          title: 'Info Secondary',
          cssVariable: '--v-info-secondary-base',
        },
        {
          title: 'Info tertiary',
          cssVariable: '--v-info-tertiary-base',
        },
        {
          title: 'Warning Primary',
          cssVariable: '--v-warning-base',
        },
        {
          title: 'Warning Secondary',
          cssVariable: '--v-warning-secondary-base',
        },
        {
          title: 'Warning tertiary',
          cssVariable: '--v-warning-tertiary-base',
        },
        {
          title: 'Error Primary',
          cssVariable: '--v-error-base',
        },
        {
          title: 'Error Secondary',
          cssVariable: '--v-error-secondary-base',
        },
        {
          title: 'Error tertiary',
          cssVariable: '--v-error-tertiary-base',
        },
      ],
    };
  },
  computed: {
    colorGroups() {
      return [
        {
          heading: 'Whitelabel Accent',
          colors: this.whiteLabelAccents,
        },
        {
          heading: 'Cool Greys',
          colors: this.coolGreys,
        },
        {
          heading: 'Status',
          colors: this.status,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.theme-card {
  border-top: 4px solid var(--v-secondary-base);
  box-sizing: border-box;
  border-radius: 4px;
  &.has-error {
    border-top: 4px solid var(--v-error-base);
  }
}
</style>
